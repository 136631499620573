import Box, { BoxProps } from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { vwMobile } from "theme/muiSizes";

export const PagesContainerBox = styled(Box)<BoxProps>(({ theme }) => ({
  padding: "40px 24px 70px",
  minHeight: "calc(100vh - 70px)",
  boxSizing: "border-box",
  position: "relative",
  overflow: "hidden",
  zIndex: 0,
  display: "flex",
  flexDirection: "column",
  backgroundImage: `radial-gradient(${theme.palette.primary.main}, ${theme.palette.background.default} 70%)`,
  [theme.breakpoints.down(440)]: {
    padding: `${vwMobile(40)} ${vwMobile(20)} ${vwMobile(70)}`,
    minHeight: `calc(100vh - ${vwMobile(70)})`,
  },
}));

export const ImageLightningBackground = styled("img")(({ theme }) => ({
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  // maxWidth: "100%",
  margin: 'auto',
  width: "100vw",
  objectFit: "scale-down",
  zIndex: 0,
  opacity: 0.5,
}));
