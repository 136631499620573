import { ImageLightningBackground, PagesContainerBox } from "./PagesContainer.styles";
import { PagesContainerProps } from "./PagesContainer.types";

/**
 * Use this container only if the pages not displayed under modal.
 * This is because the height is calculated based on the top bar
 */
export default function PagesContainer(props: PagesContainerProps) {
  const { children, isLightningBackground, pagesContainerBoxProps } = props;

  return (
    <PagesContainerBox {...pagesContainerBoxProps}>
      {isLightningBackground && (
        <ImageLightningBackground
          src={process.env.PUBLIC_URL + "/assets/images/lightning-background.png"}
          alt="lightning background"
        />
      )}
      {children}
    </PagesContainerBox>
  );
}
